import {Injectable} from '@angular/core';
import {WebTracerProvider} from '@opentelemetry/sdk-trace-web';
import {SimpleSpanProcessor} from '@opentelemetry/sdk-trace-base';
import {MeterProvider, PeriodicExportingMetricReader} from '@opentelemetry/sdk-metrics';
import {ZoneContextManager} from '@opentelemetry/context-zone';
import {context, Counter, Histogram} from '@opentelemetry/api';
import {OTLPTraceExporter} from '@opentelemetry/exporter-trace-otlp-http';
import {OTLPMetricExporter} from '@opentelemetry/exporter-metrics-otlp-http';
import {ATTR_SERVICE_NAME} from '@opentelemetry/semantic-conventions';
import {ApiEndpointsRealEstateService} from '../api-endpoints-real-estate/api-endpoints-real-estate.service';
import {Resource} from '@opentelemetry/resources';
import {AuthenticationService} from '../authentication/authentication.service';
import {UserContextService} from '../user-context/user-context.service';
@Injectable({
  providedIn: 'root',
})
export class OpenTelemetryConfigService {
  public requestDuration!: Histogram;
  public errorCount!: Counter;
  public meterProvider!: MeterProvider;
  public tracerProvider!: WebTracerProvider;

  private readonly contentType = 'application/json';

  constructor(
    private readonly apiEndpointsService: ApiEndpointsRealEstateService,
    private readonly userContextService: UserContextService,
    private readonly authService: AuthenticationService
  ) {
    if (this.userContextService.getCurrentUser() !== null) {
      this.initializeOpenTelemetry();
    } else {
      this.authService.tokenReceivedEvent$.subscribe(() => {
        this.initializeOpenTelemetry();
      });
    }
  }

  initializeOpenTelemetry() {
    const resource = new Resource({
      [ATTR_SERVICE_NAME]: 'open-telemetry-config.service',
    });

    this.tracerProvider = new WebTracerProvider({
      resource,
    });

    context.setGlobalContextManager(new ZoneContextManager());

    const otlpTraceExporter = new OTLPTraceExporter({
      url: this.apiEndpointsService.openTelemetryTraces(),
      headers: {
        Authorization: this.authService.authorizationHeader,
        'Content-Type': this.contentType,
      },
    });

    this.tracerProvider.addSpanProcessor(new SimpleSpanProcessor(otlpTraceExporter));
    this.tracerProvider.register();

    const otlpMetricsExporter = new OTLPMetricExporter({
      url: this.apiEndpointsService.openTelemetryMetrics(),
      headers: {
        Authorization: this.authService.authorizationHeader,
        'Content-Type': this.contentType,
      },
    });

    this.meterProvider = new MeterProvider({
      resource,
      readers: [
        new PeriodicExportingMetricReader({
          exporter: otlpMetricsExporter,
          exportIntervalMillis: 300000, // 5 minutes
        }),
      ],
    });

    const meter = this.meterProvider.getMeter('web-nexus-metrics');

    this.requestDuration = meter.createHistogram('request_duration', {
      description: 'Measures the duration of HTTP requests',
      unit: 'ms',
    });

    this.errorCount = meter.createCounter('error_count', {
      description: 'Counts the number of errors',
    });
  }
}
